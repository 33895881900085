import React, { useState, useEffect, useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { useSpeechRecognitionContext } from "../hooks/useSpeechToText";
import * as THREE from "three";
import { Polly } from "aws-sdk";
import { Buffer } from "buffer";

const polly = new Polly({
  credentials: {
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  },
  region: process.env.REACT_APP_REGION,
});

const visemeMap = {
  // Consonants
  p: ["mouthClose", "mouthPucker"],
  b: ["mouthClose", "mouthPucker"],
  m: ["mouthClose", "mouthPucker"],
  f: ["mouthPucker", "mouthLowerDownLeft", "mouthLowerDownRight"],
  v: ["mouthPucker", "mouthLowerDownLeft", "mouthLowerDownRight"],
  th: ["tongueOut", "mouthClose"],
  t: ["mouthClose", "tongueOut"],
  d: ["mouthClose", "tongueOut"],
  n: ["mouthClose", "tongueOut"],
  k: ["mouthClose", "tongueOut"],
  g: ["mouthClose", "tongueOut"],
  ng: ["mouthClose", "tongueOut"],
  ch: ["mouthClose", "mouthFunnel"],
  j: ["mouthClose", "mouthFunnel"],
  sh: ["mouthFunnel"],
  s: ["mouthClose", "mouthFunnel"],
  z: ["mouthClose", "mouthFunnel"],
  l: ["tongueOut"],
  r: ["mouthPucker"],

  // Vowels
  a: ["mouthOpen", "jawOpen", "tongueDown"],
  e: ["mouthSmileLeft", "mouthSmileRight", "jawOpen"],
  i: ["mouthSmileLeft", "mouthSmileRight", "mouthOpen", "tongueUp"],
  o: ["mouthFunnel", "jawOpen", "tongueDown"],
  u: ["mouthPucker", "mouthOpen", "tongueBack"],

  // Silence
  sil: ["mouthClose"],

  // Numbers (keeping as is)
  0: ["mouthFunnel", "jawOpen"],
  1: ["mouthClose", "tongueOut"],
  2: ["mouthClose", "tongueOut"],
  3: ["mouthSmileLeft", "mouthSmileRight", "mouthOpen"],
  4: ["mouthClose", "mouthFunnel"],
  5: ["mouthClose", "mouthFunnel"],
  6: ["mouthClose", "mouthFunnel"],
  7: ["mouthClose", "tongueOut"],
  8: ["mouthClose", "mouthPucker"],
  9: ["mouthClose", "mouthPucker"],

  // Special characters (keeping as is)
  "!": ["mouthOpen", "jawOpen"],
  "?": ["mouthOpen", "jawOpen"],
  ".": ["mouthClose"],
  ",": ["mouthClose"],
  "-": ["mouthClose"],
  _: ["mouthClose"],
  ":": ["mouthClose"],
  ";": ["mouthClose"],
  "(": ["mouthPucker"],
  ")": ["mouthPucker"],
  "[": ["mouthPucker"],
  "]": ["mouthPucker"],
  "{": ["mouthPucker"],
  "}": ["mouthPucker"],
  "<": ["mouthPucker"],
  ">": ["mouthPucker"],
  "/": ["mouthClose", "tongueOut"],
  "\\": ["mouthClose", "tongueOut"],
  "|": ["mouthClose", "tongueOut"],
  "@": ["mouthFunnel"],
  "#": ["mouthClose", "tongueOut"],
  $: ["mouthClose", "mouthFunnel"],
  "%": ["mouthClose", "mouthFunnel"],
  "^": ["mouthClose", "tongueOut"],
  "&": ["mouthClose", "tongueOut"],
  "*": ["mouthClose", "tongueOut"],
  "+": ["mouthClose", "tongueOut"],
  "=": ["mouthClose"],
  "~": ["mouthPucker"],
  "`": ["mouthClose"],
  "'": ["mouthClose"],
  '"': ["mouthClose"],

  // Emotions and expressions
  happy: [
    "mouthSmileLeft",
    "mouthSmileRight",
    "eyeSquintLeft",
    "eyeSquintRight",
    "cheekSquintLeft",
    "cheekSquintRight",
  ],
  sad: [
    "mouthFrownLeft",
    "mouthFrownRight",
    "browInnerUp",
    "browDownLeft",
    "browDownRight",
  ],
  angry: [
    "browDownLeft",
    "browDownRight",
    "noseSneerLeft",
    "noseSneerRight",
    "mouthFrownLeft",
    "mouthFrownRight",
  ],
  surprised: [
    "mouthOpen",
    "jawOpen",
    "browInnerUp",
    "eyeWideLeft",
    "eyeWideRight",
  ],
  disgusted: [
    "noseSneerLeft",
    "noseSneerRight",
    "mouthLeft",
    "mouthRight",
    "browDownLeft",
    "browDownRight",
  ],
  fearful: [
    "browInnerUp",
    "eyeWideLeft",
    "eyeWideRight",
    "mouthOpen",
    "jawOpen",
  ],
  neutral: ["mouthClose"],

  // Additional facial movements
  blinking: ["eyeBlinkLeft", "eyeBlinkRight"],
  winking: ["eyeBlinkLeft"],
  squinting: ["eyeSquintLeft", "eyeSquintRight"],
  browRaising: ["browOuterUpLeft", "browOuterUpRight"],
  browLowering: ["browDownLeft", "browDownRight"],
  mouthCornerPull: ["mouthLeft", "mouthRight"],
  jawForward: ["jawForward"],
  jawLeft: ["jawLeft"],
  jawRight: ["jawRight"],
  neckStretch: ["neckStretch"],

  // Tongue movements
  tongueOut: ["tongueOut"],
  tongueUp: ["tongueUp"],
  tongueDown: ["tongueDown"],
  tongueLeft: ["tongueLeft"],
  tongueRight: ["tongueRight"],
  tongueRoll: ["tongueRoll"],
  tongueBendDown: ["tongueBendDown"],
  tongueCurlUp: ["tongueCurlUp"],

  // Lip movements
  lipPucker: ["mouthPucker"],
  lipFunnel: ["mouthFunnel"],
  lipSuck: ["mouthShrugUpper", "mouthShrugLower"],
  lipPress: ["mouthPress"],
  lipStretchLeft: ["mouthStretchLeft"],
  lipStretchRight: ["mouthStretchRight"],
  lipCornerDepressLeft: ["mouthFrownLeft"],
  lipCornerDepressRight: ["mouthFrownRight"],
  lipTighten: ["mouthRollLower", "mouthRollUpper"],

  // Cheek movements
  cheekPuff: ["cheekPuff"],
  cheekSuck: ["cheekSuck"],
  cheekSquint: ["cheekSquintLeft", "cheekSquintRight"],

  // Nose movements
  noseSneer: ["noseSneerLeft", "noseSneerRight"],
  nostrilFlare: ["noseSneerLeft", "noseSneerRight"],

  // Default for any other character
  default: ["mouthClose"],
};

export function Avatar1(props) {
  const { nodes, materials } = useGLTF(
    "/models/66e41cf73403d6dd0ab4d8f7 (1).glb"
  );
  const {
    responseData,
    setIsSpeaking,
    setIsListening,
    isSpeaking,
    startListening,
    setResponseData,
  } = useSpeechRecognitionContext();
  const [cues, setCues] = useState([]);
  const startTimeRef = useRef(0);
  const [blink, setBlink] = useState(false);
  const [audioUrl, setAudioUrl] = useState("");
  const audioRef = useRef(null);
  const [lastPlayedAudioUrl, setLastPlayedAudioUrl] = useState("");
  const smoothMorphTarget = true;
  const morphTargetSmoothing = 0.1;

  const [headNod, setHeadNod] = useState(0);
  const [eyebrowRaise, setEyebrowRaise] = useState(0);
  const [handGesture, setHandGesture] = useState(0);

  const lerpMorphTarget = (target, value, speed = morphTargetSmoothing) => {
    const meshes = [
      nodes.Wolf3D_Head,
      nodes.Wolf3D_Teeth,
      nodes.Wolf3D_Eyes,
      nodes.Wolf3D_Tongue,
      nodes.Wolf3D_Body,
    ];

    meshes.forEach((mesh) => {
      if (mesh && mesh.morphTargetDictionary && mesh.morphTargetInfluences) {
        const index = mesh.morphTargetDictionary[target];
        if (index !== undefined) {
          mesh.morphTargetInfluences[index] = THREE.MathUtils.lerp(
            mesh.morphTargetInfluences[index],
            value,
            speed
          );
        }
      }
    });
  };

  function getVisemeForChar(char) {
    return visemeMap[char.toLowerCase()] || visemeMap.default;
  }

  useEffect(() => {
    let blinkTimeout;
    const nextBlink = () => {
      blinkTimeout = setTimeout(() => {
        setBlink(true);
        setTimeout(() => {
          setBlink(false);
          nextBlink();
        }, 200);
      }, THREE.MathUtils.randInt(1000, 5000));
    };
    nextBlink();
    return () => clearTimeout(blinkTimeout);
  }, []);

  useEffect(() => {
    if (!responseData) return;

    const ssmlText = `<speak>${responseData}</speak>`;

    const params = {
      TextType: "ssml",
      OutputFormat: "mp3",
      Text: ssmlText,
      VoiceId: "Brian",
    };

    polly.synthesizeSpeech(params, (err, data) => {
      if (err) {
        console.log("Error synthesizing speech:", err);
      } else {
        const audioContent = Buffer.from(data.AudioStream).toString("base64");
        const audioUrl = `data:audio/mp3;base64,${audioContent}`;
        setAudioUrl(audioUrl);
      }
    });

    const visemeParams = {
      OutputFormat: "json",
      SampleRate: "22050",
      Text: ssmlText,
      TextType: "ssml",
      VoiceId: "Brian",
      SpeechMarkTypes: ["viseme"],
    };

    polly.synthesizeSpeech(visemeParams, (err, data) => {
      if (err) {
        console.error("Error fetching viseme data:", err);
        return;
      }

      try {
        const jsonStr = Buffer.from(data.AudioStream).toString("utf8");
        const parsedJson = jsonStr
          .trim()
          .split("\n")
          .map((line) => {
            try {
              return JSON.parse(line);
            } catch {
              return null;
            }
          })
          .filter(Boolean);

        setCues(parsedJson);
        startTimeRef.current = performance.now() / 1000;
      } catch (error) {
        console.error("Error processing viseme data:", error);
      }
    });
  }, [responseData]);

  useEffect(() => {
    if (audioUrl && audioUrl !== lastPlayedAudioUrl) {
      setIsSpeaking(true);
      audioRef.current = new Audio(audioUrl);
      audioRef.current
        .play()
        .catch((error) => console.error("Error playing audio:", error));
      audioRef.current.onended = () => {
        setIsSpeaking(false);
        setResponseData("");
      };
      setLastPlayedAudioUrl(audioUrl);
    }
  }, [audioUrl, setIsSpeaking, setResponseData, lastPlayedAudioUrl]);

  useEffect(() => {
    return () => {
      // Clearing state on component unmount
      setResponseData("");
      setAudioUrl("");
      setLastPlayedAudioUrl("");
    };
  }, [setResponseData]);

  useFrame((state, delta) => {
    lerpMorphTarget("eyeBlinkLeft", blink ? 1 : 0, 0.5);
    lerpMorphTarget("eyeBlinkRight", blink ? 1 : 0, 0.5);

    const currentTime = performance.now() / 1000 - startTimeRef.current;

    Object.values(visemeMap)
      .flat()
      .forEach((morph) => {
        lerpMorphTarget(morph, 0, morphTargetSmoothing);
      });

    // Find the current viseme
    const currentCue = cues.find(
      (cue) =>
        currentTime >= cue.time / 1000 && currentTime < cue.time / 1000 + 0.1
    );

    if (currentCue) {
      const morphTargets =
        visemeMap[currentCue.value.toLowerCase()] || visemeMap.sil;
      morphTargets.forEach((morph) => {
        lerpMorphTarget(morph, 1, smoothMorphTarget ? morphTargetSmoothing : 1);
      });

      // head and eyebrow movements
      if (Math.random() < 0.05) {
        setHeadNod((Math.random() - 0.5) * 0.2);
        setEyebrowRaise(Math.random() * 0.3);
      }

      // hand gestures
      if (Math.random() < 0.02) {
        setHandGesture(Math.random());
      }
    }

    // additional animations
    lerpMorphTarget("headRotationY", headNod, 0.9);
    lerpMorphTarget("headRotation", headNod, 0.9);
    lerpMorphTarget("browInnerUp", eyebrowRaise, 0.9);

    // Hand gestures
    lerpMorphTarget("rightHandThumb1", handGesture, 0.1);
    lerpMorphTarget("rightHandIndex1", handGesture, 0.1);
    lerpMorphTarget("leftHandThumb1", handGesture, 0.1);
    lerpMorphTarget("leftHandIndex1", handGesture, 0.1);
  });

  return (
    <group {...props} dispose={null}>
      <primitive object={nodes.Hips} />
      <skinnedMesh
        name="EyeLeft"
        geometry={nodes.EyeLeft.geometry}
        material={materials.Wolf3D_Eye}
        skeleton={nodes.EyeLeft.skeleton}
        morphTargetDictionary={nodes.EyeLeft.morphTargetDictionary}
        morphTargetInfluences={nodes.EyeLeft.morphTargetInfluences}
      />
      <skinnedMesh
        name="EyeRight"
        geometry={nodes.EyeRight.geometry}
        material={materials.Wolf3D_Eye}
        skeleton={nodes.EyeRight.skeleton}
        morphTargetDictionary={nodes.EyeRight.morphTargetDictionary}
        morphTargetInfluences={nodes.EyeRight.morphTargetInfluences}
      />
      <skinnedMesh
        name="Wolf3D_Head"
        geometry={nodes.Wolf3D_Head.geometry}
        material={materials.Wolf3D_Skin}
        skeleton={nodes.Wolf3D_Head.skeleton}
        morphTargetDictionary={nodes.Wolf3D_Head.morphTargetDictionary}
        morphTargetInfluences={nodes.Wolf3D_Head.morphTargetInfluences}
      />
      <skinnedMesh
        name="Wolf3D_Teeth"
        geometry={nodes.Wolf3D_Teeth.geometry}
        material={materials.Wolf3D_Teeth}
        skeleton={nodes.Wolf3D_Teeth.skeleton}
        morphTargetDictionary={nodes.Wolf3D_Teeth.morphTargetDictionary}
        morphTargetInfluences={nodes.Wolf3D_Teeth.morphTargetInfluences}
      />
      <skinnedMesh
        geometry={nodes.Wolf3D_Glasses.geometry}
        material={materials.Wolf3D_Glasses}
        skeleton={nodes.Wolf3D_Glasses.skeleton}
      />
      <skinnedMesh
        geometry={nodes.Wolf3D_Headwear.geometry}
        material={materials.Wolf3D_Headwear}
        skeleton={nodes.Wolf3D_Headwear.skeleton}
      />
      <skinnedMesh
        geometry={nodes.Wolf3D_Body.geometry}
        material={materials.Wolf3D_Body}
        skeleton={nodes.Wolf3D_Body.skeleton}
      />
      <skinnedMesh
        geometry={nodes.Wolf3D_Outfit_Bottom.geometry}
        material={materials.Wolf3D_Outfit_Bottom}
        skeleton={nodes.Wolf3D_Outfit_Bottom.skeleton}
      />
      <skinnedMesh
        geometry={nodes.Wolf3D_Outfit_Footwear.geometry}
        material={materials.Wolf3D_Outfit_Footwear}
        skeleton={nodes.Wolf3D_Outfit_Footwear.skeleton}
      />
      <skinnedMesh
        geometry={nodes.Wolf3D_Outfit_Top.geometry}
        material={materials.Wolf3D_Outfit_Top}
        skeleton={nodes.Wolf3D_Outfit_Top.skeleton}
      />
    </group>
  );
}

useGLTF.preload("/models/66e41cf73403d6dd0ab4d8f7 (1).glb");
